@import './node_modules/@angular/material/theming';
@include mat-core();

$custom-app-primary: mat-palette($mat-blue);
$custom-app-secondary: mat-palette($mat-indigo, A200, A400, 700);
$custom-app-warn: mat-palette($mat-red);

$custom-theme: mat-light-theme($custom-app-primary, $custom-app-secondary,  $custom-app-warn);

@include angular-material-theme($custom-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }